

import Vue from 'vue'


Vue.mixin({
  methods: {
    // 智慧图谱超链接
    smarturl() {
      window.open("https://grandcanal.d-y-h.cn");//打开一个新的标签页
      // window.open("http://118.195.148.216:8082")
    },
    shadow_spectrum() {
      window.open("https://dyh.videoyi.com/m/#/login")
    }
  }
})

