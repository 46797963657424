import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const routes = [
  {
    path: "/",
    name: "Admin",
    meta: {
      title: "首页",
      keepAlive: false,
      tokenId: true,
      keyword: "大运河,文旅,平台,文旅企业,服务,江苏文旅,大运河文旅,江苏大运河,大运河文化,文化平台，中国大运河，京杭大运河，大运河官网，大运河官方网站，大运河门户网站，大运河智慧文旅，大运河文化旅游",
      description: "一个旅游项目",
      requiresAuth: true,
    },
    component: () =>
      import(/* webpackChunkName: "Admin" */ "./views/admin/Admin.vue"),
  },
  {
    path: "/Wisdom_office",
    name: "Zhbg",
    meta: {
      title: "智慧办公",
      keepAlive: false,
      tokenId: true,
      keyword: "大运河,文旅,平台,文旅企业,服务,江苏文旅,大运河文旅,江苏大运河,大运河文化,文化平台，中国大运河，京杭大运河，大运河官网，大运河官方网站，大运河门户网站，大运河智慧文旅，大运河文化旅游",
      description: "一个旅游项目",
    },
    component: () =>
      import(/* webpackChunkName: "Zhbg" */ "./views/zhjs/Zhbg.vue"),
  },
  {
    path: "/Wisdom_Toolbox",
    name: "Tygjx",
    meta: {
      title: "通用工具箱",
      keepAlive: false,
      tokenId: true,
      keyword: "大运河,文旅,平台,文旅企业,服务,江苏文旅,大运河文旅,江苏大运河,大运河文化,文化平台，中国大运河，京杭大运河，大运河官网，大运河官方网站，大运河门户网站，大运河智慧文旅，大运河文化旅游",
      description: "一个旅游项目",
    },
    component: () =>
      import(/* webpackChunkName: "Tygjx" */ "./views/zhjs/Tygjx.vue"), 
  },
  {
    path: "/Wisdom_hotel",
    name: "Zhjd",
    meta: {
      title: '智慧酒店',
      keepAlive: false,
      tokenId: true,
      keyword:"大运河,文旅,平台,文旅企业,服务,江苏文旅,大运河文旅,江苏大运河,大运河文化,文化平台，中国大运河，京杭大运河，大运河官网，大运河官方网站，大运河门户网站，大运河智慧文旅，大运河文化旅游",
      description:"一个旅游项目"
    },
    component: () =>
      import(/* webpackChunkName: "Zhjd" */ "./views/zhjs/zhjd/Zhjd.vue"),
  },
  {
    path: "/One_stop_service",
    name: "Yzsfw",
    meta: {
      title: '一站式服务',
      keepAlive: false,
      tokenId: true,
      keyword:"大运河,文旅,平台,文旅企业,服务,江苏文旅,大运河文旅,江苏大运河,大运河文化,文化平台，中国大运河，京杭大运河，大运河官网，大运河官方网站，大运河门户网站，大运河智慧文旅，大运河文化旅游",
      description:"一个旅游项目"
    },
    component: () =>
      import(/* webpackChunkName: "Yzsfw" */ "./views/zhjs/zhjd/Yzsfw.vue"),
  },
  {
    path: "/Operation_can_assign",
    name: "Yyfn",
    meta: {
      title: '运营赋能',
      keepAlive: false,
      tokenId: true,
      keyword:"大运河,文旅,平台,文旅企业,服务,江苏文旅,大运河文旅,江苏大运河,大运河文化,文化平台，中国大运河，京杭大运河，大运河官网，大运河官方网站，大运河门户网站，大运河智慧文旅，大运河文化旅游",
      description:"一个旅游项目"
    },
    component: () =>
      import(/* webpackChunkName: "Yyfn" */ "./views/yyfn/Yyfn.vue"),
  },
  
  {
    path: "/Cultural_relic_monitoring_and_early_warning",
    name: "Wwjcyj",
    meta: {
      

      
    },
    component: () =>
      import(/* webpackChunkName: "Wwjcyj" */ "./views/zhjs/Wwjcyj.vue"),
  },
  {
    path: "/The_data_analysis",
    name: "Sjfx",
    meta: {
      title: '数据分析',
      keepAlive: false,
      tokenId: true,
      keyword:"大运河,文旅,平台,文旅企业,服务,江苏文旅,大运河文旅,江苏大运河,大运河文化,文化平台，中国大运河，京杭大运河，大运河官网，大运河官方网站，大运河门户网站，大运河智慧文旅，大运河文化旅游",
      description:"一个旅游项目"
    },
    component: () =>
      import(/* webpackChunkName: "Sjfx" */ "./views/zhjs/zhjd/Sjfx.vue"),
  },
  {
    path: "/Traffic_guidance",
    name: "Jtzn",
    meta: {
      title: '交通指南',
      keepAlive: false,
      tokenId: true,
      keyword:"大运河,文旅,平台,文旅企业,服务,江苏文旅,大运河文旅,江苏大运河,大运河文化,文化平台，中国大运河，京杭大运河，大运河官网，大运河官方网站，大运河门户网站，大运河智慧文旅，大运河文化旅游",
      description:"一个旅游项目"
    },
    component: () =>
      import(/* webpackChunkName: "Jtzn" */ "./views/zhjs/Jtzn.vue"),
  },
  {
    path: "/Autogeneration_of_Travel_Notes",
    name: "Yjzdsc",
    meta: {
      title: '游记自动生成',
      keepAlive: false,
      tokenId: true,
      keyword:"大运河,文旅,平台,文旅企业,服务,江苏文旅,大运河文旅,江苏大运河,大运河文化,文化平台，中国大运河，京杭大运河，大运河官网，大运河官方网站，大运河门户网站，大运河智慧文旅，大运河文化旅游",
      description:"一个旅游项目"
    },
    component: () =>
      import(/* webpackChunkName: "Yjzdsc" */ "./views/zhjs/Yjzdsc.vue"),
  },
  {
    path: "/Service_induced",
    name: "Fwyd",
    meta: {
      title: '服务诱导',
      keepAlive: false,
      tokenId: true,
      keyword:"大运河,文旅,平台,文旅企业,服务,江苏文旅,大运河文旅,江苏大运河,大运河文化,文化平台，中国大运河，京杭大运河，大运河官网，大运河官方网站，大运河门户网站，大运河智慧文旅，大运河文化旅游",
      description:"一个旅游项目"
    },
    component: () =>
      import(/* webpackChunkName: "Fwyd" */ "./views/zhjs/Fwyd.vue"),
  },
  {
    path: "/The_guide_tour",
    name: "Dydl",
    meta: {
      title: '导游导览',
      keepAlive: false,
      tokenId: true,
      keyword:"大运河,文旅,平台,文旅企业,服务,江苏文旅,大运河文旅,江苏大运河,大运河文化,文化平台，中国大运河，京杭大运河，大运河官网，大运河官方网站，大运河门户网站，大运河智慧文旅，大运河文化旅游",
      description:"一个旅游项目"
    },
    component: () =>
      import(/* webpackChunkName: "Dydl" */ "./views/zhjs/Dydl.vue"),
  },
  { path: "/404", component: () => import("./views/admin/Admin.vue") },
  // 404 page must be placed at the end !!!
  { path: "*", redirect: "/404", component: () => import("./views/admin/Admin.vue") },
  
  {
    path: "/museum",
    name: "智慧博物馆",
    meta: {
      keepAlive: false,
      tokenId: true,
      keyword:"大运河,文旅,平台,文旅企业,服务,江苏文旅,大运河文旅,江苏大运河,大运河文化,文化平台，中国大运河，京杭大运河，大运河官网，大运河官方网站，大运河门户网站，大运河智慧文旅，大运河文化旅游",
      description:"一个旅游项目"
    },
    component: (resolve) => require(["./views/zhjs/Bwg.vue"], resolve),
  },
  {
    path: "/operation",
    name: "文旅项目运营赋能",
    meta: {
      keepAlive: false,
      tokenId: true,
      keyword:"大运河,文旅,平台,文旅企业,服务,江苏文旅,大运河文旅,江苏大运河,大运河文化,文化平台，中国大运河，京杭大运河，大运河官网，大运河官方网站，大运河门户网站，大运河智慧文旅，大运河文化旅游",
      description:"一个旅游项目"
    },
    component: (resolve) => require(["./views/Whzc/Wlyyfn.vue"], resolve),
  },
  {
    path: "/wisdom",
    name: "知识图谱",
    meta: {
      keepAlive: false,
      tokenId: true,
      keyword:"大运河,文旅,平台,文旅企业,服务,江苏文旅,大运河文旅,江苏大运河,大运河文化,文化平台，中国大运河，京杭大运河，大运河官网，大运河官方网站，大运河门户网站，大运河智慧文旅，大运河文化旅游",
      description:"一个旅游项目"
    },
    component: (resolve) => require(["./views/Whzc/Txzstp.vue"], resolve),
  },
  {
    path: "/wisdom_scenic_spot",
    name: "智慧景区",
    meta: {
      keepAlive: false,
      tokenId: true,
      keyword:"大运河,文旅,平台,文旅企业,服务,江苏文旅,大运河文旅,江苏大运河,大运河文化,文化平台，中国大运河，京杭大运河，大运河官网，大运河官方网站，大运河门户网站，大运河智慧文旅，大运河文化旅游",
      description:"一个旅游项目"
    },
    component: (resolve) =>
      require(["./views/wisdom_scenic_spot.vue"], resolve),
  },
  {
    path: "/schleimigen",
    name: "智慧文创",
    meta: {
      keepAlive: false,
      tokenId: true,
      keyword:"大运河,文旅,平台,文旅企业,服务,江苏文旅,大运河文旅,江苏大运河,大运河文化,文化平台，中国大运河，京杭大运河，大运河官网，大运河官方网站，大运河门户网站，大运河智慧文旅，大运河文化旅游",
      description:"一个旅游项目"
    },
    component: (resolve) => require(["./views/zhjs/Zhwc.vue"], resolve),
  },
  {
    path: "/shadow_spectrum",
    name: "影谱宣传",
    meta: {
      keepAlive: false,
      tokenId: true,
      keyword:"大运河,文旅,平台,文旅企业,服务,江苏文旅,大运河文旅,江苏大运河,大运河文化,文化平台，中国大运河，京杭大运河，大运河官网，大运河官方网站，大运河门户网站，大运河智慧文旅，大运河文化旅游",
      description:"一个旅游项目"
    },
    component: (resolve) => require(["./views/shadow_spectrum.vue"], resolve),
  },
  {
    path: "/optimization_algorithm",
    name: "营销优化算法",
    meta: {
      keepAlive: false,
      tokenId: true,
      keyword:"大运河,文旅,平台,文旅企业,服务,江苏文旅,大运河文旅,江苏大运河,大运河文化,文化平台，中国大运河，京杭大运河，大运河官网，大运河官方网站，大运河门户网站，大运河智慧文旅，大运河文化旅游",
      description:"一个旅游项目"
    },
    component: (resolve) =>
      require(["./views/optimization_algorithm.vue"], resolve),
  },
  {
    path: "/service",
    name: "智能客服",
    meta: {
      keepAlive: false,
      tokenId: true,
      keyword:"大运河,文旅,平台,文旅企业,服务,江苏文旅,大运河文旅,江苏大运河,大运河文化,文化平台，中国大运河，京杭大运河，大运河官网，大运河官方网站，大运河门户网站，大运河智慧文旅，大运河文化旅游",
      description:"一个旅游项目"
    },
    component: (resolve) => require(["./views/zhjs/service.vue"], resolve),
  },
  {
    path: "/traveldistribution",
    name: "旅游产品分销平台",
    meta: {
      keepAlive: false,
      tokenId: true,
      keyword:"大运河,文旅,平台,文旅企业,服务,江苏文旅,大运河文旅,江苏大运河,大运河文化,文化平台，中国大运河，京杭大运河，大运河官网，大运河官方网站，大运河门户网站，大运河智慧文旅，大运河文化旅游",
      description:"一个旅游项目"
    },
    component: (resolve) =>
      require(["./views/srdemo/touristtrain/touristtrain.vue"], resolve),
  },
  {
    path: "/paid_on_fuiton",
    name: "薪福通",
    meta: {
      keepAlive: false,
      tokenId: true,
      keyword:"大运河,文旅,平台,文旅企业,服务,江苏文旅,大运河文旅,江苏大运河,大运河文化,文化平台，中国大运河，京杭大运河，大运河官网，大运河官方网站，大运河门户网站，大运河智慧文旅，大运河文化旅游",
      description:"一个旅游项目"
    },
    component: (resolve) =>
      require(["./views/yyfn/paid_on_fuiton.vue"], resolve),
  },
  {
    path: "/emergency_command",
    name: "应急指挥详情",
    meta: {
      keepAlive: false,
      tokenId: true,
      keyword:"大运河,文旅,平台,文旅企业,服务,江苏文旅,大运河文旅,江苏大运河,大运河文化,文化平台，中国大运河，京杭大运河，大运河官网，大运河官方网站，大运河门户网站，大运河智慧文旅，大运河文化旅游",
      description:"一个旅游项目"
    },
    component: (resolve) =>
      require(["./views/zhjs/emergency_command.vue"], resolve),
  },
  {
    path: "/ByteDance",
    name: "字节跳动",
    meta: {
      keepAlive: false,
      tokenId: true,
      keyword:"大运河,文旅,平台,文旅企业,服务,江苏文旅,大运河文旅,江苏大运河,大运河文化,文化平台，中国大运河，京杭大运河，大运河官网，大运河官方网站，大运河门户网站，大运河智慧文旅，大运河文化旅游",
      description:"一个旅游项目"
    },
    component: (resolve) => require(["./views/yyfn/ByteDance.vue"], resolve),
  },
  {
    path: "/mini_program",
    name: "电商小程序",
    meta: {
      keepAlive: false,
      tokenId: true,
      keyword:"大运河,文旅,平台,文旅企业,服务,江苏文旅,大运河文旅,江苏大运河,大运河文化,文化平台，中国大运河，京杭大运河，大运河官网，大运河官方网站，大运河门户网站，大运河智慧文旅，大运河文化旅游",
      description:"一个旅游项目"
    },
    component: (resolve) => require(["./views/yyfn/mini_program.vue"], resolve),
  },
  {
    path: "/arithmetic",
    name: "物联及算法",
    meta: {
      keepAlive: false,
      tokenId: true,
      keyword:"大运河,文旅,平台,文旅企业,服务,江苏文旅,大运河文旅,江苏大运河,大运河文化,文化平台，中国大运河，京杭大运河，大运河官网，大运河官方网站，大运河门户网站，大运河智慧文旅，大运河文化旅游",
      description:"一个旅游项目"
    },
    component: (resolve) => require(["./views/yyfn/arithmetic.vue"], resolve),
  },
  {
    path: "/virtual_museum",
    name: "虚拟博物馆",
    meta: {
      keepAlive: false,
      tokenId: true,
      keyword:"大运河,文旅,平台,文旅企业,服务,江苏文旅,大运河文旅,江苏大运河,大运河文化,文化平台，中国大运河，京杭大运河，大运河官网，大运河官方网站，大运河门户网站，大运河智慧文旅，大运河文化旅游",
      description:"一个旅游项目"
    },
    component: (resolve) =>
      require(["./views/zhjs/virtual_museum.vue"], resolve),
  },
  {
    path: "/foot_shopping",
    name: "美食购物",
    meta: {
      keepAlive: false,
      tokenId: true,
      keyword:"大运河,文旅,平台,文旅企业,服务,江苏文旅,大运河文旅,江苏大运河,大运河文化,文化平台，中国大运河，京杭大运河，大运河官网，大运河官方网站，大运河门户网站，大运河智慧文旅，大运河文化旅游",
      description:"一个旅游项目"
    },
    component: (resolve) =>
      require(["./views/zhjs/foot_shopping.vue"], resolve),
  },
  {
    path: "/museum_details",
    name: "博物馆营销详情",
    meta: {
      keepAlive: false,
      tokenId: true,
      keyword:"大运河,文旅,平台,文旅企业,服务,江苏文旅,大运河文旅,江苏大运河,大运河文化,文化平台，中国大运河，京杭大运河，大运河官网，大运河官方网站，大运河门户网站，大运河智慧文旅，大运河文化旅游",
      description:"一个旅游项目"
    },
    component: (resolve) =>
      require(["./views/zhjs/museun_details.vue"], resolve),
  },
  {
    path: "/live_streaming",
    name: "在线直播",
    meta: {
      keepAlive: false,
      tokenId: true,
      keyword:"大运河,文旅,平台,文旅企业,服务,江苏文旅,大运河文旅,江苏大运河,大运河文化,文化平台，中国大运河，京杭大运河，大运河官网，大运河官方网站，大运河门户网站，大运河智慧文旅，大运河文化旅游",
      description:"一个旅游项目"
    },
    component: (resolve) =>
      require(["./views/zhjs/live_streaming.vue"], resolve),
  },
  {
    path: "/marketing",
    name: "企业营销",
    meta: {
      keepAlive: false,
      tokenId: true,
      keyword:"大运河,文旅,平台,文旅企业,服务,江苏文旅,大运河文旅,江苏大运河,大运河文化,文化平台，中国大运河，京杭大运河，大运河官网，大运河官方网站，大运河门户网站，大运河智慧文旅，大运河文化旅游",
      description:"一个旅游项目"
    },
    component: (resolve) => require(["./views/marketing.vue"], resolve),
  },
  {
    path: "/cultural_relic",
    name: "文物线上活化能力",
    meta: {
      keepAlive: false,
      tokenId: true,
      keyword:"大运河,文旅,平台,文旅企业,服务,江苏文旅,大运河文旅,江苏大运河,大运河文化,文化平台，中国大运河，京杭大运河，大运河官网，大运河官方网站，大运河门户网站，大运河智慧文旅，大运河文化旅游",
      description:"一个旅游项目"
    },
    component: (resolve) => require(["./views/cultural_relic.vue"], resolve),
  },
  {
    path: "/hardware_deployment",
    name: "景区硬件部署方案",
    meta: {
      keepAlive: false,
      tokenId: true,
      keyword:"大运河,文旅,平台,文旅企业,服务,江苏文旅,大运河文旅,江苏大运河,大运河文化,文化平台，中国大运河，京杭大运河，大运河官网，大运河官方网站，大运河门户网站，大运河智慧文旅，大运河文化旅游",
      description:"一个旅游项目"
    },
    component: (resolve) =>
      require(["./views/hardware_deployment.vue"], resolve),
  },
  {
    path: "/way_recommend",
    name: "路线推荐详情",
    meta: {
      keepAlive: false,
      tokenId: true,
      keyword:"大运河,文旅,平台,文旅企业,服务,江苏文旅,大运河文旅,江苏大运河,大运河文化,文化平台，中国大运河，京杭大运河，大运河官网，大运河官方网站，大运河门户网站，大运河智慧文旅，大运河文化旅游",
      description:"一个旅游项目"
    },
    component: (resolve) => require(["./views/way_recommend.vue"], resolve),
  },
  {
    path: "/journey",
    name: "行程规划",
    meta: {
      keepAlive: false,
      tokenId: true,
      keyword:"大运河,文旅,平台,文旅企业,服务,江苏文旅,大运河文旅,江苏大运河,大运河文化,文化平台，中国大运河，京杭大运河，大运河官网，大运河官方网站，大运河门户网站，大运河智慧文旅，大运河文化旅游",
      description:"一个旅游项目"
    },
    component: (resolve) => require(["./views/journey.vue"], resolve),
  },
  {
    path: "/cultural_activity",
    name: "文化活动",
    meta: {
      keepAlive: false,
      tokenId: true,
      keyword:"大运河,文旅,平台,文旅企业,服务,江苏文旅,大运河文旅,江苏大运河,大运河文化,文化平台，中国大运河，京杭大运河，大运河官网，大运河官方网站，大运河门户网站，大运河智慧文旅，大运河文化旅游",
      description:"一个旅游项目"
    },
    component: (resolve) => require(["./views/cultural_activity.vue"], resolve),
  },
  {
    path: "/visual",
    name: "可视化大屏集中管控详情",
    meta: {
      keepAlive: false,
      tokenId: true,
      keyword:"大运河,文旅,平台,文旅企业,服务,江苏文旅,大运河文旅,江苏大运河,大运河文化,文化平台，中国大运河，京杭大运河，大运河官网，大运河官方网站，大运河门户网站，大运河智慧文旅，大运河文化旅游",
      description:"一个旅游项目"
    },
    component: (resolve) => require(["./views/visual.vue"], resolve),
  },
  {
    path: "/big_data",
    name: "智慧景区大数据",
    meta: {
      keepAlive: false,
      tokenId: true,
      keyword:"大运河,文旅,平台,文旅企业,服务,江苏文旅,大运河文旅,江苏大运河,大运河文化,文化平台，中国大运河，京杭大运河，大运河官网，大运河官方网站，大运河门户网站，大运河智慧文旅，大运河文化旅游",
      description:"一个旅游项目"
    },
    component: (resolve) => require(["./views/big_data.vue"], resolve),
  },
  {
    path: "/trapoer",
    name: "trapoer",
    meta: {
      keepAlive: false,
      tokenId: true,
      keyword:"大运河,文旅,平台,文旅企业,服务,江苏文旅,大运河文旅,江苏大运河,大运河文化,文化平台，中国大运河，京杭大运河，大运河官网，大运河官方网站，大运河门户网站，大运河智慧文旅，大运河文化旅游",
      description:"一个旅游项目"
    },
    component: () => import("@/views/srdemo/trapoer/trapoer.vue"),
  },
  {
    path: "/power_sum",
    name: "power_sum",
    meta: {
      keepAlive: false,
      tokenId: true,
      keyword:"大运河,文旅,平台,文旅企业,服务,江苏文旅,大运河文旅,江苏大运河,大运河文化,文化平台，中国大运河，京杭大运河，大运河官网，大运河官方网站，大运河门户网站，大运河智慧文旅，大运河文化旅游",
      description:"一个旅游项目"
    },
    component: () => import("@/views/srdemo/powersum/powersum.vue"),
  },
  {
    path: "/film_xun",
    name: "film_xun",
    meta: {
      keepAlive: false,
      tokenId: true,
      keyword:"大运河,文旅,平台,文旅企业,服务,江苏文旅,大运河文旅,江苏大运河,大运河文化,文化平台，中国大运河，京杭大运河，大运河官网，大运河官方网站，大运河门户网站，大运河智慧文旅，大运河文化旅游",
      description:"一个旅游项目"
    },
    component: () => import("@/views/srdemo/filmxun/filmxun.vue"),
  },
  {
    path: "/e_mall",
    name: "e_mall",
    meta: {
      keepAlive: false,
      tokenId: true,
      keyword:"大运河,文旅,平台,文旅企业,服务,江苏文旅,大运河文旅,江苏大运河,大运河文化,文化平台，中国大运河，京杭大运河，大运河官网，大运河官方网站，大运河门户网站，大运河智慧文旅，大运河文化旅游",
      description:"一个旅游项目"
    },
    component: () => import("@/views/srdemo/emall/emall.vue"),
  },
  {
    path: "/play_page",
    name: "playpage",
    meta: {
      keepAlive: false,
      tokenId: true,
      keyword:"大运河,文旅,平台,文旅企业,服务,江苏文旅,大运河文旅,江苏大运河,大运河文化,文化平台，中国大运河，京杭大运河，大运河官网，大运河官方网站，大运河门户网站，大运河智慧文旅，大运河文化旅游",
      description:"一个旅游项目"
    },
    component: () => import("@/views/srdemo/playpage/playpage.vue"),
  },
  {
    path: "/linkage",
    name: "linkage",
    meta: {
      keepAlive: false,
      tokenId: true,
      keyword:"大运河,文旅,平台,文旅企业,服务,江苏文旅,大运河文旅,江苏大运河,大运河文化,文化平台，中国大运河，京杭大运河，大运河官网，大运河官方网站，大运河门户网站，大运河智慧文旅，大运河文化旅游",
      description:"一个旅游项目"
    },
    component: () => import("@/views/srdemo/znldd/linkage.vue"),
  },
  {
    path: "/brand_mark",
    name: "brand_mark",
    meta: {
      keepAlive: false,
      tokenId: true,
      keyword:"大运河,文旅,平台,文旅企业,服务,江苏文旅,大运河文旅,江苏大运河,大运河文化,文化平台，中国大运河，京杭大运河，大运河官网，大运河官方网站，大运河门户网站，大运河智慧文旅，大运河文化旅游",
      description:"一个旅游项目"
    },
    component: () => import("@/views/srdemo/brandmark/brandmark.vue"),
  },
  {
    path: "/vr_films",
    name: "vr_films",
    meta: {
      keepAlive: false,
      tokenId: true,
      keyword:"大运河,文旅,平台,文旅企业,服务,江苏文旅,大运河文旅,江苏大运河,大运河文化,文化平台，中国大运河，京杭大运河，大运河官网，大运河官方网站，大运河门户网站，大运河智慧文旅，大运河文化旅游",
      description:"一个旅游项目"
    },
    component: () => import("@/views/srdemo/vrfilms/vrfilms.vue"),
  },
  {
    path: "/ecological",
    name: "大运河企业生态服务",
    meta: {
      keepAlive: false,
      tokenId: true,
      keyword:"大运河,文旅,平台,文旅企业,服务,江苏文旅,大运河文旅,江苏大运河,大运河文化,文化平台，中国大运河，京杭大运河，大运河官网，大运河官方网站，大运河门户网站，大运河智慧文旅，大运河文化旅游",
      description:"一个旅游项目"
    },
    // component: () => import(["@/views/ecological.vue"],resolve)
    component: (resolve) => require(["./views/ecological.vue"], resolve),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    // return 期望滚动到哪个的位置
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

export default router;
