import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueLazyload from 'vue-lazyload'
Vue.use(VueLazyload, {
  preLoad: 1.3,
  loading: 'static/srbanner/1.gif',
  attempt: 1
})
// 配置 ElementUI
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

// Vue.prototype.openLoading = function () {
//   const loading = this.$loading({           // 声明一个loading对象
//     lock: true,                             // 是否锁屏
//     text: '加载中...',                     // 加载动画的文字
//     spinner: 'el-icon-loading',             // 引入的loading图标
//     background: 'rgba(0, 0, 0, 0.5)',       // 背景颜色
//     target: '.sub-main',                    // 需要遮罩的区域
//     body: true,
//     customClass: 'mask'                     // 遮罩层新增类名
//   })
//   setTimeout(function () {                  // 设定定时器，超时5S后自动关闭遮罩层，避免请求失败时，遮罩层一直存在的问题
//     loading.close();                        // 关闭遮罩层
//   }, 5000)
//   return loading;
// }


// 配置 全局初始化样式  @代表src目录
import '@/assets/css/reset.scss';

Vue.config.productionTip = false

//工具
import '@/utils/mixins.js'



import "./dynamic"
// Vue.prototype.$ = $; // 当然还有这句话 给vue原型上添加 $
new Vue({
  router,
  store,
  render: h => h(App),
  mounted () {
    //document.dispatchEvent(new Event('render-event'))
    document.dispatchEvent(new Event('custom-render-trigger'))
  }
}).$mount('#app')