<template>
  <div id="app">
    <main class="main">
      <div class="header">
        <img src="./app_img/logo1.jpg" class="img1" />
        <img src="./app_img/logo2.png" class="img2" />
        <span class="fh1">大运河文旅企业服务平台</span>
        <i class="el-icon-close" v-show="icon" @click="tb"></i>
        <div class="bord1" v-show="bord1"></div>
        <div class="bord2" v-show="bord2"></div>
        <div class="bord3" v-show="bord3"></div>
        <div class="bord4" v-show="bord4"></div>
        <div class="bord5" v-show="bord5"></div>
        <img src="./components/imgs/kfz.jpg" alt="" class="kfz" v-show="kfz" />
        <el-button
          type="text"
          class="sy"
          @click="jump('/')"
          @mouseover.native="sya"
        >
          <span @click="sy">首页</span>
        </el-button>
        <el-button
          type="text"
          @mouseover.native="zhjs"
          class="zhjs"
          @click="zhdjs"
          >智慧建设</el-button
        >
        <div class="box" v-show="bigbox" @mouseleave="gbox" @click="wslbox">
          <div class="xbox">
            <el-button
              type="text"
              class="zhjq"
              @click="jump('/museum')"
              @mouseover.native="xbox5"
            >
              <span class="zhjq1">智慧博物馆</span>
            </el-button>
            <el-button
              type="text"
              class="Wisdom_hotel"
              @click="jump('/Wisdom_hotel')"
              @mouseover.native="xbox1"
            >
              <span class="Wisdom_hotel1">智慧酒店</span>
            </el-button>
            <el-button
              type="text"
              class="zhwc"
              @click="jump('/schleimigen')"
              @mouseover.native="xbox2"
            >
              <span class="zhwc1">智慧文创</span>
            </el-button>
            <el-button
              type="text"
              class="Wisdom_Toolbox"
              @click="jump('/Wisdom_Toolbox')"
              @mouseover.native="xbox3"
            >
              <span class="Wisdom_Toolbox1">智慧工具箱</span>
            </el-button>
            <el-button
              type="text"
              class="Wisdom_office"
              @click="jump('/Wisdom_office')"
              @mouseover.native="xbox4"
            >
              <span class="Wisdom_office1">智慧办公</span>
            </el-button>
            <el-button
              type="text"
              class="zhbwg"
              @click="jump('/wisdom_scenic_spot')"
              @mouseover.native="xbox"
            >
              <span class="zhbwg1">智慧景区</span>
            </el-button>
          </div>
          <div class="sbox5" v-show="sbox">
            <span class="jcgnl">基础类功能</span>
            <span class="jqhlw">景区互联网与大数据</span>
            <span class="jqwh">景区文化生活</span>
            <div class="border1"></div>
            <div class="border2"></div>
            <div class="border3"></div>
            <el-button
              type="text"
              class="jtzn"
              @click="jump('/Traffic_guidance')"
              >交通指南</el-button
            >
            <el-button
              type="text"
              class="whhd"
              @click="jump('/cultural_activity')"
              >文化活动</el-button
            >
            <el-button type="text" class="xcgh" @click="jump('/journey')"
              >行程规划</el-button
            >
            <el-button type="text" class="znkf" @click="jump('/service')"
              >智能客服</el-button
            >
            <el-button type="text" class="yyxq" @click="jump('/play_page')"
              >游园详情</el-button
            >
            <el-button type="text" class="lxtj" @click="jump('/way_recommend')"
              >路线推荐</el-button
            >
            <el-button type="text" class="zxzb" @click="jump('/live_streaming')"
              >在线直播</el-button
            >
            <el-button type="text" disabled class="ai">AI识景</el-button>
            <el-button type="text" class="dydh" @click="jump('/The_guide_tour')"
              >导游导览</el-button
            >
            <el-button type="text" class="msgw" @click="jump('/foot_shopping')"
              >美食购物</el-button
            >
            <el-button type="text" class="dzsc" @click="jump('/e_mall')"
              >电子商城</el-button
            >
            <el-button type="text" class="vrty" @click="jump('/vr_films')"
              >VR体验</el-button
            >
            <el-button type="text" class="wljsf" @click="jump('/arithmetic')"
              >物联及算法</el-button
            >
            <el-button type="text" class="znld" @click="jump('/linkage')"
              >智能联动</el-button
            >
            <el-button
              type="text"
              class="yjzh"
              @click="jump('/emergency_command')"
              >应急指挥</el-button
            >
            <el-button type="text" class="dsj" @click="jump('/big_data')"
              >智慧景区大数据</el-button
            >
            <el-button type="text" class="ksh" @click="jump('/visual')"
              >可视化大屏集中管控</el-button
            >
            <el-button
              type="text"
              class="fwyd"
              @click="jump('/Service_induced')"
              >服务诱导</el-button
            >
            <el-button
              type="text"
              class="yjzdsc"
              @click="jump('/Autogeneration_of_Travel_Notes')"
              >游记自动生成</el-button
            >
            <el-button
              type="text"
              class="tjbs"
              @click="jump('/hardware_deployment')"
              >硬件部署方案</el-button
            >
            <el-button type="text" disabled class="jqots">景区OTS </el-button>
          </div>
          <div class="sbox1" v-show="sbox1">
            <span class="yzsfw">一站式服务</span>
            <div class="bor1"></div>
            <div class="bor2"></div>
            <span class="qyjy">企业营销</span>
            <el-button
              type="text"
              class="qjxf"
              @click="jump('/One_stop_service')"
              >360°全景选房</el-button
            >
            <el-button
              type="text"
              class="yckq"
              @click="jump('/One_stop_service')"
              >远程开启空调</el-button
            >
            <el-button
              type="text"
              class="wlqt"
              @click="jump('/One_stop_service')"
              >未来前台</el-button
            >
            <el-button
              type="text"
              class="wkkm"
              @click="jump('/One_stop_service')"
              >无卡开门</el-button
            >
            <el-button
              type="text"
              class="hyms"
              @click="jump('/One_stop_service')"
              >欢迎模式</el-button
            >
            <el-button
              type="text"
              class="skkf"
              @click="jump('/One_stop_service')"
              >声控客房</el-button
            >
            <el-button
              type="text"
              class="zdy"
              @click="jump('/One_stop_service')"
              >自定义情景模式</el-button
            >
            <el-button
              type="text"
              class="znyy"
              @click="jump('/One_stop_service')"
              >智能语音管家</el-button
            >
            <el-button
              type="text"
              class="zbwl"
              @click="jump('/One_stop_service')"
              >物联周边</el-button
            >
            <el-button
              type="text"
              class="yykp"
              @click="jump('/One_stop_service')"
              >预约开票</el-button
            >
            <el-button
              type="text"
              class="yjtf"
              @click="jump('/One_stop_service')"
              >一键退房</el-button
            >
            <el-button type="text" class="fwyx" @click="jump('/marketing')"
              >引流裂变</el-button
            >
            <el-button type="text" class="fwlc" @click="jump('/marketing')"
              >服务留存</el-button
            >
            <el-button type="text" class="chyy" @click="jump('/marketing')"
              >促活运营</el-button
            >
            <el-button type="text" class="zhfg" @click="jump('/marketing')"
              >转化复购</el-button
            >
            <el-button type="text" class="fpts" @click="jump('/marketing')"
              >复盘提升</el-button
            >
            <span class="dsjfx">大数据分析决策</span>
            <div class="bor3"></div>
            <el-button
              type="text"
              class="sjfx1"
              @click="jump('/The_data_analysis')"
              >数据分析</el-button
            >
            <el-button
              type="text"
              class="ydd"
              @click="jump('/The_data_analysis')"
              >经营分析平台移动端</el-button
            >
            <el-button
              type="text"
              class="yhhx"
              @click="jump('/The_data_analysis')"
              >用户画像体系</el-button
            >
          </div>
          <div class="sbox2" v-show="sbox2">
            <el-button type="text" class="qb1">全部</el-button>
            <div class="border1"></div>
            <el-button type="text" class="yspkf" @click="jump('/schleimigen')"
              >衍生品开发</el-button
            >
            <el-button type="text" class="cjsj" @click="jump('/schleimigen')"
              >主题场景设计</el-button
            >
            <el-button type="text" class="cpsj" @click="jump('/schleimigen')"
              >主题餐饮产品设计</el-button
            >
            <el-button type="text" class="wlsj" @click="jump('/schleimigen')"
              >文旅二消升级</el-button
            >
          </div>
          <div class="sbox3" v-show="sbox3">
            <span class="qb2">全部</span>
            <div class="border2"></div>
            <el-button type="text" class="jqmp" @click="jump('/Wisdom_Toolbox')"
              >景区名片</el-button
            >
            <el-button type="text" class="cyfw" @click="jump('/Wisdom_Toolbox')"
              >智能导览</el-button
            >
            <el-button type="text" class="mzb" @click="jump('/Wisdom_Toolbox')"
              >景区天气</el-button
            >
            <el-button type="text" class="pw" @click="jump('/Wisdom_Toolbox')"
              >活动日历</el-button
            >
            <el-button type="text" class="znfy" @click="jump('/Wisdom_Toolbox')"
              >游玩攻略</el-button
            >
            <el-button type="text" class="swdt" @click="jump('/Wisdom_Toolbox')"
              >新闻资讯</el-button
            >
            <el-button type="text" class="zndl" @click="jump('/Wisdom_Toolbox')"
              >分时预约</el-button
            >
            <el-button type="text" class="zsfw" @click="jump('/Wisdom_Toolbox')"
              >活动报名</el-button
            >
            <el-button type="text" class="fsyy" @click="jump('/Wisdom_Toolbox')"
              >电商</el-button
            >
            <el-button type="text" class="zbsc" @click="jump('/Wisdom_Toolbox')"
              >景区商圈</el-button
            >
            <el-button
              type="text"
              class="tsjpj"
              @click="jump('/Wisdom_Toolbox')"
              >AI智能客服</el-button
            >
            <el-button type="text" class="nryx" @click="jump('/Wisdom_Toolbox')"
              >AI识别花草</el-button
            >
            <el-button type="text" class="tqzs" @click="jump('/Wisdom_Toolbox')"
              >景区明信片</el-button
            >
            <el-button type="text" class="hdbm" @click="jump('/Wisdom_Toolbox')"
              >个人中心</el-button
            >
            <el-button type="text" class="ds" @click="jump('/Wisdom_Toolbox')"
              >用户评论</el-button
            >
            <el-button
              type="text"
              class="znsbhc"
              @click="jump('/Wisdom_Toolbox')"
              >用户评星</el-button
            >
          </div>
          <div class="sbox4" v-show="sbox4">
            <span class="qb3">全部</span>
            <div class="border3"></div>
            <el-button type="text" class="jstx" @click="jump('/Wisdom_office')"
              >即时通讯</el-button
            >
            <el-button type="text" class="ychy" @click="jump('/Wisdom_office')"
              >远程会议</el-button
            >
            <el-button type="text" class="wxtp" @click="jump('/Wisdom_office')"
              >无线投屏</el-button
            >
          </div>
          <div class="sbox" v-show="sbox5">
            <span class="ykfw">游客服务</span>
            <div class="border4"></div>
            <span class="gcyw">馆藏业务</span>
            <div class="border5"></div>
            <span class="bwgyx">博物馆营销</span>
            <div class="border6"></div>
            <el-button
              type="text"
              class="ynbwg"
              @click="jump('/virtual_museum')"
              >虚拟博物馆</el-button
            >
            <el-button type="text" class="wwkp" @click="jump('/cultural_relic')"
              >AR扫码文物卡片</el-button
            >
            <el-button type="text" disabled class="dzdt">电子地图</el-button>
            <el-button type="text" disabled class="dmtjj">多媒体讲解</el-button>
            <el-button type="text" disabled class="swksh">三维可视化</el-button>
            <el-button type="text" disabled class="dlp">导览屏</el-button>
            <el-button type="text" disabled class="jdgl">鉴定管理</el-button>
            <el-button type="text" disabled class="kfgl">库房管理</el-button>
            <el-button type="text" disabled class="cpgl">藏品管理</el-button>
            <el-button type="text" disabled class="zlgl">展览管理</el-button>
            <el-button type="text" disabled class="xfgl">修复管理</el-button>
            <el-button type="text" class="lcgl" @click="jump('/museum_details')"
              >全流程策展
            </el-button>
            <el-button type="text" class="sjyx" @click="jump('/museum_details')"
              >纪念品设计及营销策划</el-button
            >
            <span class="dzxg">电子巡更</span>
            <div class="border7"></div>
            <el-button type="text" class="znxg" @click="jump('/film_xun')"
              >智能巡更系统</el-button
            >
            <span class="zhjk">智能监控</span>
            <div class="border8"></div>
            <span class="fxyy">智能分析应用</span>
            <div class="border9"></div>
            <el-button type="text" disabled class="zyww">重要文物</el-button>
            <el-button type="text" disabled class="zyqy">重要区域</el-button>
            <el-button type="text" disabled class="bwgg">博物馆周界</el-button>
            <el-button type="text" disabled class="qtfx">其他分析</el-button>
          </div>
        </div>

        <el-button
          type="text"
          @click="jump('/Operation_can_assign')"
          @mouseover.native="Operation_can_assign"
          class="Operation_can_assign"
        >
          <span @click="yyfn"> 运营赋能</span></el-button
        >
        <div class="div" v-show="div" @mouseleave="divq" @click="wsldiv">
          <div class="xdiv">
            <el-button
              type="text"
              class="btn1"
              @mouseover.native="btn1"
              @click="jump('/Operation_can_assign')"
            >
              <span class="yyl">运营类</span>
            </el-button>
            <el-button
              type="text"
              class="btn2"
              @mouseover.native="btn2"
              @click="jump('/Operation_can_assign')"
            >
              <span class="yxl"> 营销类</span>
            </el-button>
            <el-button type="text" class="btn3" @mouseover.native="btn3">
              <a
                class="whip"
                href="https://ip.jscaee.com.cn/portal/#/index/home"
                target="_blank"
              >
                文化IP交易</a
              >
            </el-button>
          </div>
          <div class="ydiv" v-show="ydiv">
            <span class="qb6">全部</span>
            <div class="border10"></div>
            <el-button type="text" class="whly" @click="jump('/trapoer')"
              >文旅项目规划运营</el-button
            >
            <el-button type="text" class="wlxm" @click="jump('/operation')"
              >文旅项目运营赋能</el-button
            >
            <el-button
              type="text"
              class="yxcp"
              @click="jump('/traveldistribution')"
              >旅游产品分销平台</el-button
            >
            <el-button type="text" class="dscx" @click="jump('/mini_program')"
              >电商小程序</el-button
            >
            <el-button
              disabled
              type="text"
              class="sjsj"
              @click="jump('/The_visual_design')"
              >视觉设计</el-button
            >
            <el-button disabled type="text" class="yynl"
              >研学运营能力</el-button
            >
          </div>
          <div class="pdiv" v-show="pdiv">
            <img src="./app_img/whip.jpg" alt="" class="pimg" />
          </div>
          <div class="zdiv" v-show="zdiv">
            <span class="qb7">全部</span>
            <div class="border11"></div>
            <el-button type="text" class="ppyx" @click="jump('/brand_mark')"
              >品牌营销</el-button
            >
            <el-button
              type="text"
              class="yxyh"
              @click="jump('/optimization_algorithm')"
              >营销优化算法</el-button
            >
            <el-button type="text" class="zstp" @click="jump('/wisdom')"
              >知识图谱</el-button
            >

            <el-button type="text" class="xft" @click="jump('/ByteDance')"
              >字节跳动</el-button
            >
            <el-button
              type="text"
              class="zjtd"
              @click="jump('/shadow_spectrum')"
              >影谱智慧影像</el-button
            >
          </div>
        </div>

        <el-button
          type="text"
          class="jztz"
          @mouseover.native="jztz"
          @click="jump('/paid_on_fuiton')"
          ><span @click="jztzz"> 金融服务</span></el-button
        >
        <el-button
          type="text"
          @click="jump('/ecological')"
          class="nljh"
          @mouseover.native="nljh"
        >
          <span @click="nnjh">生态服务</span>
        </el-button>
        <!-- <div class="powersty" v-show="power" @mouseleave="powerbox" @click="powercli">
          <div>
            <el-button
              type="text"
              class="btn1"
              @mouseover.native="btn1"
              @click="jump('/ecological')"
            >
              <span class="powersty1">大运河企业生态服务</span>
            </el-button>
          </div>
        </div> -->
      </div>
      <div class="mocks" v-show="showdia"></div>
    </main>
    <router-view />
  </div>
</template>
<script>
export default {
  mounted() {
    window.onscroll = function() {
      var scrollTop = 0;
      if (document.documentElement && document.documentElement.scrollTop) {
        scrollTop = document.documentElement.scrollTop;
      } else if (document.body) {
        scrollTop = document.body.scrollTop;
      }

      if (scrollTop < 300) {
        console.log("ok");
        document.querySelector(".header").style.backgroundColor = "";
      } else {
        document.querySelector(".header").style.backgroundColor = "black";
      }
    };
  },
  data() {
    return {
      bigbox: false,
      sbox: true,
      sbox1: false,
      sbox2: false,
      sbox3: false,
      sbox4: false,
      sbox5: false,
      div: false,
      ydiv: true,
      zdiv: false,
      icon: false,
      kfz: false,
      bord1: false,
      bord2: false,
      bord3: false,
      bord4: false,
      bord5: false,
      showdia: false,
      pdiv: false,
      power:false,
    };
  },

  methods: {
    wsldiv(){
this.bord2 = false;
     this.bord1 = false;
     this.bord3 = true;
     this.bord4 = false;
     this.bord5 = false;
    },
    wslbox(){
     this.bord2 = true;
     this.bord1 = false;
     this.bord3 = false;
     this.bord4 = false;
     this.bord5 = false;
    },
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    },

    nnjh() {
      this.bord1 = false;
      this.bord2 = false;
      this.bord3 = false;
      this.bord4 = false;
      this.bord5 = true;
    },
    powercli(){
      this.bord1 = false;
      this.bord2 = false;
      this.bord3 = false;
      this.bord4 = false;
      this.bord5 = true;
    },

    yyfn() {
      this.bord1 = false;
      this.bord2 = false;
      this.bord3 = true;
      this.bord4 = false;
      this.bord5 = false;
    },
    sy() {
      this.bord1 = true;
      this.bord2 = false;
      this.bord3 = false;
      this.bord4 = false;
      this.bord5 = false;
    },
    zhdjs() {
      this.bord2 = true;
      this.bord1 = false;
      this.bord3 = false;
      this.bord4 = false;
      this.bord5 = false;
    },
    jzt() {
      this.icon = true;
      this.kfz = true;
    },
    tb() {
      this.showdia = false;
      this.icon = false;
      this.kfz = false;
    },
    nljh() {
      this.power = true
      this.div = false;
      this.bigbox = false;
    },
    jztz() {
      this.div = false;
      this.bigbox = false;
      this.power= false
    },
    jztzz() {
      // this.showdia = true;
      this.bord1 = false;
      this.bord2 = false;
      this.bord3 = false;
      this.bord4 = true;
      this.bord5 = false;
    },
    sya() {
      this.div = false;
      this.bigbox = false;
      this.power= false
    },
    divq() {
      this.div = false;
    },
    gbox() {
      this.bigbox = false;
    },
    powerbox(){
      this.power = false
    },
    Operation_can_assign() {
      this.div = true;
      this.bigbox = false;
      this.power= false
    },
    zhjs() {
      this.bigbox = true;
      this.div = false;
      this.pdiv = false;
      this.power= false
    },
    btn2() {
      this.ydiv = false;
      this.zdiv = true;
      this.pdiv = false;
    },
    btn3() {
      this.ydiv = false;
      this.zdiv = false;
      this.pdiv = true;
    },
    btn1() {
      this.ydiv = true;
      this.zdiv = false;
      this.pdiv = false;
    },
    xbox() {
      this.sbox = true;
      this.sbox5 = false;
      this.sbox4 = false;
      this.sbox3 = false;
      this.sbox2 = false;
      this.sbox1 = false;
    },
    xbox1() {
      this.sbox5 = false;
      this.sbox4 = false;
      this.sbox3 = false;
      this.sbox2 = false;
      this.sbox1 = true;
      this.sbox = false;
    },
    xbox2() {
      this.sbox5 = false;
      this.sbox4 = false;
      this.sbox3 = false;
      this.sbox2 = true;
      this.sbox1 = false;
      this.sbox = false;
    },
    xbox3() {
      this.sbox5 = false;
      this.sbox4 = false;
      this.sbox3 = true;
      this.sbox2 = false;
      this.sbox1 = false;
      this.sbox = false;
    },
    xbox4() {
      this.sbox5 = false;
      this.sbox4 = true;
      this.sbox3 = false;
      this.sbox2 = false;
      this.sbox1 = false;
      this.sbox = false;
    },
    xbox5() {
      this.sbox5 = true;
      this.sbox4 = false;
      this.sbox3 = false;
      this.sbox2 = false;
      this.sbox1 = false;
      this.sbox = false;
    },
  },
};
</script>
<style lang="scss" scoped>
* {
  padding: 0 !important;
  margin: 0 !important;
}
.mocks {
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.5;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1;
}
#app {
  text-align: center;
}
.powersty{
  min-width: 300px;
  height: 50px;
  background: rgba(0, 0, 0, 0.68);
  border: 1px solid #666666;
  position: absolute;
  left: 1300px;
  top: 80px;
  .powersty1{
    line-height: 50px;
    font-size: 18px;
        color: #ffffff;
  }
}
.div {
  min-width: 500px;
  height: 317px;
  background: rgba(0, 0, 0, 0.68);
  border: 1px solid #666666;
  position: absolute;
  left: 800px;
  top: 80px;
  .pdiv {
    min-width: 296px;
    height: 317px;
    position: absolute;
    top: 0;
    left: 204px;
    display: flex;
    justify-content: center;
    align-items: center;
    .pimg {
      width: 239px;
      height: 206px;
    }
  }
  .xdiv {
    min-width: 204px;
    height: 317px;
    background: rgba(0, 0, 0, 0.72);
    position: absolute;
    top: 0;
    left: 0;
    .btn1 {
      min-width: 204px;
      height: 54px;
      position: absolute;
      top: 24px;
      left: 0;
      .yyl {
        min-width: 48px;
        height: 22px;
        font-size: 18px;
        color: #ffffff;
        position: absolute;
        top: 18px;
        left: 24px;
      }
    }
    .btn1:hover {
      background-color: gray;
      background-image: url(./components/imgs/icon1.png);
      background-repeat: no-repeat;
      background-position-x: 150px;
      background-position-y: center;
    }
    .btn2 {
      min-width: 204px;
      height: 54px;
      position: absolute;
      top: 78px;
      left: 0;
      .yxl {
        min-width: 48px;
        height: 22px;
        font-size: 18px;
        color: #ffffff;
        position: absolute;
        top: 18px;
        left: 24px;
      }
    }
    .btn2:hover {
      background-color: gray;
      background-image: url(./components/imgs/icon1.png);
      background-repeat: no-repeat;
      background-position-x: 150px;
      background-position-y: center;
    }
    .btn3 {
      min-width: 204px;
      height: 54px;
      position: absolute;
      top: 132px;
      left: 0;
      .whip {
        min-width: 48px;
        height: 22px;
        font-size: 18px;
        color: #ffffff;
        position: absolute;
        top: 18px;
        left: 24px;
      }
    }
    .btn3:hover {
      background-color: gray;
      // background-image: url(./components/imgs/icon1.png);
      // background-repeat: no-repeat;
      // background-position-x: 150px;
      // background-position-y: center;
    }
  }
  .ydiv {
    min-width: 296px;
    height: 317px;
    position: absolute;
    top: 0;
    left: 204px;
    .qb6 {
      min-width: 32px;
      height: 22px;
      font-size: 18px;
      color: #ffffff;
      position: absolute;
      top: 40px;
      left: 64px;
    }
    .border10 {
      width: 168px;
      height: 1px;
      background: #666666;
      position: absolute;
      top: 78px;
      left: 64px;
    }
    .whly {
      min-width: 32px;
      height: 22px;
      font-size: 16px;
      color: #ffffff;
      position: absolute;
      top: 97px;
      left: 64px;
    }
    .wlxm {
      min-width: 32px;
      height: 22px;
      font-size: 16px;
      color: #ffffff;
      position: absolute;
      top: 129px;
      left: 64px;
    }
    .yxcp {
      min-width: 32px;
      height: 22px;
      font-size: 16px;
      color: #ffffff;
      position: absolute;
      top: 161px;
      left: 64px;
    }
    .dscx {
      min-width: 32px;
      height: 22px;
      font-size: 16px;
      color: #ffffff;
      position: absolute;
      top: 193px;
      left: 64px;
    }
    .sjsj {
      min-width: 32px;
      height: 22px;
      font-size: 16px;
      // color: #666666;
      position: absolute;
      top: 225px;
      left: 64px;
    }
    .yynl {
      min-width: 32px;
      height: 22px;
      font-size: 16px;
      // color: #666666;
      position: absolute;
      top: 257px;
      left: 64px;
    }
  }
  .zdiv {
    min-width: 296px;
    height: 317px;
    position: absolute;
    top: 0;
    left: 204px;
    .qb7 {
      min-width: 32px;
      height: 22px;
      font-size: 18px;
      color: #ffffff;
      position: absolute;
      top: 40px;
      left: 64px;
    }
    .border11 {
      width: 168px;
      height: 1px;
      background: #666666;
      position: absolute;
      top: 78px;
      left: 64px;
    }
    .ppyx {
      min-width: 32px;
      height: 22px;
      font-size: 16px;
      color: #ffffff;
      position: absolute;
      top: 97px;
      left: 64px;
    }
    .yxyh {
      min-width: 32px;
      height: 22px;
      font-size: 16px;
      color: #ffffff;
      position: absolute;
      top: 129px;
      left: 64px;
    }
    .zstp {
      min-width: 32px;
      height: 22px;
      font-size: 16px;
      color: #ffffff;
      position: absolute;
      top: 161px;
      left: 64px;
    }
    .xft {
      min-width: 32px;
      height: 22px;
      font-size: 16px;
      color: #ffffff;
      position: absolute;
      top: 193px;
      left: 64px;
    }
    .zjtd {
      min-width: 32px;
      height: 22px;
      font-size: 16px;
      color: #ffffff;
      position: absolute;
      top: 225px;
      left: 64px;
    }
    .zhyx {
      min-width: 32px;
      height: 22px;
      font-size: 16px;
      color: #ffffff;
      position: absolute;
      top: 257px;
      left: 64px;
    }
  }
}

* {
  margin: 0 !important;
  padding: 0 !important;
}
.main {
  // position: relative;
  top: 0;
  left: 0;
  z-index: 10;
  .header {
    height: 80px;
    min-width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    background-color: rgba(0, 0, 0, 0.2);
    .el-icon-close {
      position: absolute;
      top: 13.021vw;
      left: 1230px;
      z-index: 5;
      font-size: 20px;
    }
    .kfz {
      width: 600px;
      height: 400px;
      position: absolute;
      top: 250px;
      left: 650px;
      background-color: #ffffff;
    }
    .bord1 {
      min-width: 80px;
      height: 2px;
      background-color: #ffffff;
      position: absolute;
      top: 80px;
      left: 870px;
    }
    .bord2 {
      min-width: 100px;
      height: 2px;
      background-color: #ffffff;
      position: absolute;
      top: 80px;
      left: 978px;
    }
    .bord3 {
      min-width: 100px;
      height: 2px;
      background-color: #ffffff;
      position: absolute;
      top: 80px;
      left: 1119px;
    }
    .bord4 {
      min-width: 100px;
      height: 2px;
      background-color: #ffffff;
      position: absolute;
      top: 80px;
      left: 1260px;
    }
    .bord5 {
      min-width: 100px;
      height: 2px;
      background-color: #ffffff;
      position: absolute;
      top: 80px;
      left: 1400px;
    }
    .img1 {
      width: 53px;
      height: 53px;
      position: absolute;
      top: 14px;
      left: 294px;
    }
    .img2 {
      width: 87px;
      height: 53px;
      position: absolute;
      top: 14px;
      left: 364px;
    }
    .fh1 {
      position: absolute;
      min-width: 275px;
      height: 33px;
      font-size: 24px;
      color: #ffffff;
      top: 23px;
      left: 502px;
    }

    .sy {
      min-width: 40px;
      height: 28px;
      font-size: 20px;
      font-weight: 400;
      color: #ffffff;
      line-height: 28px;
      position: absolute;
      top: 27px;
      left: 886px;
    }
    .zhjs {
      min-width: 80px;
      height: 28px;
      font-size: 20px;
      font-weight: 400;
      color: #ffffff;
      line-height: 28px;
      position: absolute;
      top: 27px;
      left: 985px;
    }
    .Operation_can_assign {
      min-width: 80px;
      height: 28px;
      font-size: 20px;
      font-weight: 400;
      color: #ffffff;
      line-height: 28px;
      position: absolute;
      top: 27px;
      left: 1126px;
    }
    .jztz {
      min-width: 80px;
      height: 28px;
      font-size: 20px;
      font-weight: 400;
      color: #ffffff;
      line-height: 28px;
      position: absolute;
      top: 27px;
      left: 1266px;
    }
    .nljh {
      min-width: 80px;
      height: 28px;
      font-size: 20px;
      font-weight: 400;
      color: #ffffff;
      line-height: 28px;
      position: absolute;
      top: 27px;
      left: 1405px;
    }
  }
}
.box {
  min-width: 964px;
  min-height: 509px;
  background-color: rgba(0, 0, 0, 0.8);
  border: 1px solid #666666;
  position: absolute;
  top: 80px;
  left: 500px;
  z-index: 10;
  .sbox {
    min-width: 760px;
    min-height: 600px;
    position: absolute;
    left: 204px;
    top: 0px;
    .ykfw {
      min-width: 64px;
      height: 22px;
      font-size: 18px;
      color: #ffffff;
      position: absolute;
      top: 40px;
      left: 64px;
    }
    .border4 {
      width: 168px;
      height: 1px;
      background: #666666;
      position: absolute;
      top: 78px;
      left: 64px;
    }
    .gcyw {
      min-width: 64px;
      height: 22px;
      font-size: 18px;
      color: #ffffff;
      position: absolute;
      top: 40px;
      left: 296px;
    }
    .border5 {
      width: 168px;
      height: 1px;
      background: #666666;
      position: absolute;
      top: 78px;
      left: 296px;
    }
    .bwgyx {
      min-width: 64px;
      height: 22px;
      font-size: 18px;
      color: #ffffff;
      position: absolute;
      top: 40px;
      left: 528px;
    }
    .border6 {
      width: 168px;
      height: 1px;
      background: #666666;
      position: absolute;
      top: 78px;
      left: 528px;
    }
    .ynbwg {
      min-width: 64px;
      height: 22px;
      font-size: 16px;
      color: #ffffff;
      position: absolute;
      top: 97px;
      left: 64px;
    }
    .wwkp {
      min-width: 64px;
      height: 22px;
      font-size: 16px;
      color: #ffffff;
      position: absolute;
      top: 129px;
      left: 64px;
    }
    .dzdt {
      min-width: 64px;
      height: 22px;
      font-size: 16px;
      // color: #ffffff;
      position: absolute;
      top: 161px;
      left: 64px;
    }
    .dmtjj {
      min-width: 64px;
      height: 22px;
      font-size: 16px;
      // color: #ffffff;
      position: absolute;
      top: 193px;
      left: 64px;
    }
    .swksh {
      min-width: 64px;
      height: 22px;
      font-size: 16px;
      // color: #ffffff;
      position: absolute;
      top: 225px;
      left: 64px;
    }
    .dlp {
      min-width: 64px;
      height: 22px;
      font-size: 16px;
      // color: #ffffff;
      position: absolute;
      top: 257px;
      left: 64px;
      text-align: left;
    }
    .jdgl {
      min-width: 64px;
      height: 22px;
      font-size: 16px;
      // color: #ffffff;
      position: absolute;
      top: 97px;
      left: 296px;
    }
    .kfgl {
      min-width: 64px;
      height: 22px;
      font-size: 16px;
      // color: #ffffff;
      position: absolute;
      top: 129px;
      left: 296px;
    }
    .cpgl {
      min-width: 64px;
      height: 22px;
      font-size: 16px;
      // color: #ffffff;
      position: absolute;
      top: 161px;
      left: 296px;
    }
    .zlgl {
      min-width: 64px;
      height: 22px;
      font-size: 16px;
      // color: #ffffff;
      position: absolute;
      top: 193px;
      left: 296px;
    }
    .xfgl {
      min-width: 64px;
      height: 22px;
      font-size: 16px;
      // color: #ffffff;
      position: absolute;
      top: 225px;
      left: 296px;
    }
    .lcgl {
      min-width: 64px;
      height: 22px;
      font-size: 16px;
      color: #ffffff;
      position: absolute;
      top: 97px;
      left: 528px;
    }
    .sjyx {
      min-width: 64px;
      height: 22px;
      font-size: 16px;
      color: #ffffff;
      position: absolute;
      top: 129px;
      left: 528px;
    }
    .dzxg {
      min-width: 64px;
      height: 22px;
      font-size: 18px;
      color: #ffffff;
      position: absolute;
      top: 309px;
      left: 64px;
    }
    .border7 {
      width: 168px;
      height: 1px;
      background: #666666;
      position: absolute;
      top: 347px;
      left: 64px;
    }
    .znxg {
      min-width: 64px;
      height: 22px;
      font-size: 16px;
      color: #ffffff;
      position: absolute;
      top: 366px;
      left: 64px;
    }
    .zhjk {
      min-width: 64px;
      height: 22px;
      font-size: 18px;
      color: #ffffff;
      position: absolute;
      top: 277px;
      left: 296px;
    }
    .border8 {
      width: 168px;
      height: 1px;
      background: #666666;
      position: absolute;
      top: 315px;
      left: 296px;
    }
    .fxyy {
      min-width: 64px;
      height: 22px;
      font-size: 18px;
      color: #ffffff;
      position: absolute;
      top: 181px;
      left: 528px;
    }
    .border9 {
      width: 168px;
      height: 1px;
      background: #666666;
      position: absolute;
      top: 219px;
      left: 528px;
    }
    .zyww {
      min-width: 64px;
      height: 22px;
      font-size: 16px;
      // color: #ffffff;
      position: absolute;
      top: 238px;
      left: 528px;
    }
    .zyqy {
      min-width: 64px;
      height: 22px;
      font-size: 16px;
      // color: #ffffff;
      position: absolute;
      top: 270px;
      left: 528px;
    }
    .bwgg {
      min-width: 64px;
      height: 22px;
      font-size: 16px;
      // color: #ffffff;
      position: absolute;
      top: 302px;
      left: 528px;
    }
    .qtfx {
      min-width: 64px;
      height: 22px;
      font-size: 16px;
      // color: #ffffff;
      position: absolute;
      top: 334px;
      left: 528px;
    }
  }
  .sbox1 {
    min-width: 760px;
    min-height: 600px;
    position: absolute;
    left: 204px;
    top: 0px;
    .yzsfw {
      min-width: 80px;
      height: 22px;
      font-size: 18px;
      color: #ffffff;
      position: absolute;
      top: 40px;
      left: 64px;
    }
    .bor1 {
      min-width: 400px;
      height: 1px;
      background: #666666;
      position: absolute;
      top: 78px;
      left: 64px;
    }
    .bor2 {
      min-width: 168px;
      height: 1px;
      background: #666666;
      position: absolute;
      top: 78px;
      left: 528px;
    }
    .qyjy {
      min-width: 64px;
      height: 22px;
      font-size: 18px;
      color: #ffffff;
      position: absolute;
      top: 40px;
      left: 528px;
    }
    .qjxf {
      min-width: 56px;
      height: 20px;
      font-size: 16px;
      color: #ffff;
      position: absolute;
      top: 97px;
      left: 64px;
    }
    .yckq {
      min-width: 56px;
      height: 20px;
      font-size: 16px;
      color: #ffff;
      position: absolute;
      top: 129px;
      left: 64px;
    }
    .wlqt {
      min-width: 56px;
      height: 20px;
      font-size: 16px;
      color: #ffff;
      position: absolute;
      top: 163px;
      left: 64px;
    }
    .wkkm {
      min-width: 56px;
      height: 20px;
      font-size: 16px;
      color: #ffff;
      position: absolute;
      top: 195px;
      left: 64px;
    }
    .hyms {
      min-width: 56px;
      height: 20px;
      font-size: 16px;
      color: #ffff;
      position: absolute;
      top: 227px;
      left: 64px;
    }
    .skkf {
      min-width: 56px;
      height: 20px;
      font-size: 16px;
      color: #ffff;
      position: absolute;
      top: 259px;
      left: 64px;
    }
    .zdy {
      min-width: 56px;
      height: 20px;
      font-size: 16px;
      color: #ffff;
      position: absolute;
      top: 291px;
      left: 64px;
    }
    .znyy {
      min-width: 56px;
      height: 20px;
      font-size: 16px;
      color: #ffff;
      position: absolute;
      top: 323px;
      left: 64px;
    }
    .zbwl {
      min-width: 56px;
      height: 20px;
      font-size: 16px;
      color: #ffff;
      position: absolute;
      top: 355px;
      left: 64px;
    }
    .yykp {
      min-width: 56px;
      height: 20px;
      font-size: 16px;
      color: #ffff;
      position: absolute;
      top: 97px;
      left: 296px;
    }
    .yjtf {
      min-width: 56px;
      height: 20px;
      font-size: 16px;
      color: #ffff;
      position: absolute;
      top: 129px;
      left: 296px;
    }
    .fwyx {
      min-width: 56px;
      height: 20px;
      font-size: 16px;
      color: #ffff;
      position: absolute;
      top: 97px;
      left: 528px;
    }
    .fwlc {
      min-width: 56px;
      height: 20px;
      font-size: 16px;
      color: #ffff;
      position: absolute;
      top: 129px;
      left: 528px;
    }
    .chyy {
      min-width: 56px;
      height: 20px;
      font-size: 16px;
      color: #ffff;
      position: absolute;
      top: 161px;
      left: 528px;
    }
    .zhfg {
      min-width: 56px;
      height: 20px;
      font-size: 16px;
      color: #ffff;
      position: absolute;
      top: 193px;
      left: 528px;
    }
    .fpts {
      min-width: 56px;
      height: 20px;
      font-size: 16px;
      color: #ffff;
      position: absolute;
      top: 225px;
      left: 528px;
    }
    .dsjfx {
      min-width: 56px;
      height: 22px;
      font-size: 18px;
      color: #ffffff;
      position: absolute;
      top: 270px;
      left: 528px;
    }
    .bor3 {
      width: 168px;
      height: 1px;
      background: #666666;
      position: absolute;
      top: 305px;
      left: 528px;
    }
    .sjfx1 {
      min-width: 56px;
      height: 22px;
      font-size: 16px;
      color: #ffffff;
      position: absolute;
      top: 320px;
      left: 528px;
    }
    .ydd {
      min-width: 56px;
      height: 22px;
      font-size: 16px;
      color: #ffffff;
      position: absolute;
      top: 352px;
      left: 528px;
    }
    .yhhx {
      min-width: 56px;
      height: 22px;
      font-size: 16px;
      color: #ffffff;
      position: absolute;
      top: 384px;
      left: 528px;
    }
  }
  .sbox2 {
    min-width: 760px;
    min-height: 600px;
    position: absolute;
    left: 204px;
    top: 0px;
    .qb1 {
      min-width: 32px;
      height: 22px;
      font-size: 18px;
      color: #ffffff;
      position: absolute;
      top: 40px;
      left: 64px;
    }
    .border1 {
      width: 632px;
      height: 1px;
      background: #666666;
      position: absolute;
      top: 78px;
      left: 64px;
    }
    .yspkf {
      min-width: 32px;
      height: 22px;
      font-size: 16px;
      color: #ffffff;
      position: absolute;
      top: 97px;
      left: 64px;
    }
    .cjsj {
      min-width: 32px;
      height: 22px;
      font-size: 16px;
      color: #ffffff;
      position: absolute;
      top: 97px;
      left: 296px;
    }
    .cpsj {
      min-width: 32px;
      height: 22px;
      font-size: 16px;
      color: #ffffff;
      position: absolute;
      top: 97px;
      left: 528px;
    }
    .wlsj {
      min-width: 32px;
      height: 22px;
      font-size: 16px;
      color: #ffffff;
      position: absolute;
      top: 129px;
      left: 64px;
    }
  }
  .sbox3 {
    min-width: 760px;
    min-height: 600px;
    position: absolute;
    left: 204px;
    top: 0px;
    .qb2 {
      min-width: 32px;
      height: 22px;
      font-size: 18px;
      color: #ffffff;
      position: absolute;
      top: 40px;
      left: 64px;
    }
    .border2 {
      width: 632px;
      height: 1px;
      background: #666666;
      position: absolute;
      top: 78px;
      left: 64px;
    }
    .jqmp {
      min-width: 28px;
      height: 20px;
      font-size: 16px;
      color: #ffff;
      position: absolute;
      top: 97px;
      left: 64px;
    }
    .cyfw {
      min-width: 28px;
      height: 20px;
      font-size: 16px;
      color: #ffff;
      position: absolute;
      top: 129px;
      left: 64px;
    }
    .mzb {
      min-width: 28px;
      height: 20px;
      font-size: 16px;
      color: #ffff;
      position: absolute;
      top: 161px;
      left: 64px;
    }
    .pw {
      min-width: 28px;
      height: 20px;
      font-size: 16px;
      color: #ffff;
      position: absolute;
      top: 193px;
      left: 64px;
    }
    .znfy {
      min-width: 28px;
      height: 20px;
      font-size: 16px;
      color: #ffff;
      position: absolute;
      top: 225px;
      left: 64px;
    }
    .swdt {
      min-width: 28px;
      height: 20px;
      font-size: 16px;
      color: #ffff;
      position: absolute;
      top: 257px;
      left: 64px;
    }
    .zndl {
      min-width: 28px;
      height: 20px;
      font-size: 16px;
      color: #ffff;
      position: absolute;
      top: 97px;
      left: 296px;
    }
    .zsfw {
      min-width: 28px;
      height: 20px;
      font-size: 16px;
      color: #ffff;
      position: absolute;
      top: 129px;
      left: 296px;
    }
    .fsyy {
      min-width: 28px;
      height: 20px;
      font-size: 16px;
      color: #ffff;
      position: absolute;
      top: 161px;
      left: 296px;
    }
    .zbsc {
      min-width: 28px;
      height: 20px;
      font-size: 16px;
      color: #ffff;
      position: absolute;
      top: 193px;
      left: 296px;
    }
    .tsjpj {
      min-width: 28px;
      height: 20px;
      font-size: 16px;
      color: #ffff;
      position: absolute;
      top: 225px;
      left: 296px;
    }
    .nryx {
      min-width: 28px;
      height: 20px;
      font-size: 16px;
      color: #ffff;
      position: absolute;
      top: 257px;
      left: 296px;
    }
    .tqzs {
      min-width: 28px;
      height: 20px;
      font-size: 16px;
      color: #ffff;
      position: absolute;
      top: 97px;
      left: 528px;
    }
    .hdbm {
      min-width: 28px;
      height: 20px;
      font-size: 16px;
      color: #ffff;
      position: absolute;
      top: 129px;
      left: 528px;
    }
    .ds {
      min-width: 28px;
      height: 20px;
      font-size: 16px;
      color: #ffff;
      position: absolute;
      top: 161px;
      left: 528px;
    }
    .znsbhc {
      min-width: 28px;
      height: 20px;
      font-size: 16px;
      color: #ffff;
      position: absolute;
      top: 193px;
      left: 528px;
    }
    .jkm {
      min-width: 28px;
      height: 20px;
      font-size: 16px;
      color: #ffff;
      position: absolute;
      top: 225px;
      left: 528px;
    }
  }
  .sbox4 {
    min-width: 760px;
    min-height: 600px;
    position: absolute;
    left: 204px;
    top: 0px;
    .qb3 {
      min-width: 28px;
      height: 20px;
      font-size: 18px;
      color: #ffff;
      position: absolute;
      top: 40px;
      left: 64px;
    }
    .border3 {
      width: 632px;
      height: 1px;
      background: #666666;
      position: absolute;
      top: 78px;
      left: 64px;
    }
    .jstx {
      min-width: 28px;
      height: 20px;
      font-size: 16px;
      color: #ffff;
      position: absolute;
      top: 97px;
      left: 64px;
    }
    .ychy {
      min-width: 28px;
      height: 20px;
      font-size: 16px;
      color: #ffff;
      position: absolute;
      top: 97px;
      left: 296px;
    }
    .wxtp {
      min-width: 28px;
      height: 20px;
      font-size: 16px;
      color: #ffff;
      position: absolute;
      top: 97px;
      left: 528px;
    }
  }
  .sbox5 {
    min-width: 760px;
    min-height: 600px;
    position: absolute;
    left: 204px;
    top: 0px;
    .jcgnl {
      min-width: 80px;
      height: 22px;
      font-size: 18px;
      color: #ffffff;
      position: absolute;
      top: 40px;
      left: 64px;
    }
    .jqhlw {
      min-width: 144px;
      height: 22px;
      font-size: 18px;
      color: #ffffff;
      position: absolute;
      top: 40px;
      left: 296px;
    }
    .jqwh {
      min-width: 96px;
      height: 22px;
      font-size: 18px;
      color: #ffffff;
      position: absolute;
      top: 40px;
      left: 528px;
    }
    .border1 {
      min-width: 168px;
      height: 1px;
      background: #666666;
      position: absolute;
      left: 64px;
      top: 78px;
    }
    .border2 {
      min-width: 168px;
      height: 1px;
      background: #666666;
      position: absolute;
      left: 296px;
      top: 78px;
    }
    .border3 {
      min-width: 168px;
      height: 1px;
      background: #666666;
      position: absolute;
      left: 528px;
      top: 78px;
    }
    .jtzn {
      min-width: 56px;
      height: 20px;
      font-size: 16px;
      font-weight: 500;
      color: #ffffff;
      position: absolute;
      top: 97px;
      left: 64px;
    }
    .whhd {
      min-width: 56px;
      height: 20px;
      font-size: 16px;
      font-weight: 500;
      color: #ffffff;
      position: absolute;
      top: 129px;
      left: 64px;
    }
    .xcgh {
      min-width: 56px;
      height: 20px;
      font-size: 16px;
      font-weight: 500;
      color: #ffffff;
      position: absolute;
      top: 161px;
      left: 64px;
    }
    .znkf {
      min-width: 56px;
      height: 20px;
      font-size: 16px;
      font-weight: 500;
      color: #ffffff;
      position: absolute;
      top: 193px;
      left: 64px;
    }
    .yyxq {
      min-width: 56px;
      height: 20px;
      font-size: 16px;
      font-weight: 500;
      color: #ffffff;
      position: absolute;
      top: 225px;
      left: 64px;
    }
    .lxtj {
      min-width: 56px;
      height: 20px;
      font-size: 16px;
      font-weight: 500;
      color: #ffffff;
      position: absolute;
      top: 257px;
      left: 64px;
    }
    .zxzb {
      min-width: 56px;
      height: 20px;
      font-size: 16px;
      font-weight: 500;
      color: #ffffff;
      position: absolute;
      top: 289px;
      left: 64px;
    }
    .ai {
      min-width: 56px;
      height: 20px;
      font-size: 16px;
      font-weight: 500;
      // color: #ffffff;
      position: absolute;
      top: 321px;
      left: 64px;
      text-align: left;
    }
    .dydh {
      min-width: 56px;
      height: 20px;
      font-size: 16px;
      font-weight: 500;
      color: #ffffff;
      position: absolute;
      top: 353px;
      left: 64px;
    }
    .msgw {
      min-width: 56px;
      height: 20px;
      font-size: 16px;
      font-weight: 500;
      color: #ffffff;
      position: absolute;
      top: 385px;
      left: 64px;
    }
    .dzsc {
      min-width: 56px;
      height: 20px;
      font-size: 16px;
      font-weight: 500;
      color: #ffffff;
      position: absolute;
      top: 417px;
      left: 64px;
    }
    .vrty {
      min-width: 56px;
      height: 20px;
      font-size: 16px;
      font-weight: 500;
      color: #ffffff;
      position: absolute;
      top: 449px;
      left: 64px;
    }
    .wljsf {
      min-width: 56px;
      height: 20px;
      font-size: 16px;
      color: #ffff;
      position: absolute;
      top: 97px;
      left: 296px;
    }
    .znld {
      min-width: 56px;
      height: 20px;
      font-size: 16px;
      color: #ffff;
      position: absolute;
      top: 129px;
      left: 296px;
    }
    .yjzh {
      min-width: 56px;
      height: 20px;
      font-size: 16px;
      color: #ffff;
      position: absolute;
      top: 161px;
      left: 296px;
    }
    .dsj {
      min-width: 56px;
      height: 20px;
      font-size: 16px;
      color: #ffff;
      position: absolute;
      top: 193px;
      left: 296px;
    }
    .ksh {
      min-width: 56px;
      height: 20px;
      font-size: 16px;
      color: #ffff;
      position: absolute;
      top: 225px;
      left: 296px;
    }
    .fwyd {
      min-width: 56px;
      height: 20px;
      font-size: 16px;
      color: #ffff;
      position: absolute;
      top: 97px;
      left: 528px;
    }
    .yjzdsc {
      min-width: 56px;
      height: 20px;
      font-size: 16px;
      color: #ffff;
      position: absolute;
      top: 129px;
      left: 528px;
    }
    .tjbs {
      min-width: 56px;
      height: 20px;
      font-size: 16px;
      color: #ffff;
      position: absolute;
      top: 161px;
      left: 528px;
    }
    .jqots {
      min-width: 56px;
      height: 20px;
      font-size: 16px;
      // color: #ffff;
      position: absolute;
      top: 193px;
      left: 528px;
    }
  }
  .xbox {
    min-width: 204px;
    min-height: 509px;
    background: rgba(0, 0, 0, 0.72);
    position: absolute;
    top: 0;
    left: 0;
    .zhjq {
      min-width: 204px;
      min-height: 54px;
      position: absolute;
      top: 24px;
      left: 0;
      .zhjq1 {
        min-width: 64px;
        min-height: 22px;
        font-size: 18px;
        font-weight: 400;
        color: #ffffff;
        position: absolute;
        top: 18px;
        left: 24px;
      }
    }
    .zhjq:hover {
      background-color: gray;
      background-image: url(./components/imgs/icon1.png);
      background-repeat: no-repeat;
      background-position-x: 150px;
      background-position-y: center;
    }
    .Wisdom_hotel {
      min-width: 204px;
      min-height: 54px;
      position: absolute;
      top: 78px;
      left: 0;
      .Wisdom_hotel1 {
        min-width: 64px;
        min-height: 22px;
        font-size: 18px;
        font-weight: 400;
        color: #ffffff;
        position: absolute;
        top: 18px;
        left: 24px;
      }
    }
    .Wisdom_hotel:hover {
      background-color: gray;
      background-image: url(./components/imgs/icon1.png);
      background-repeat: no-repeat;
      background-position-x: 150px;
      background-position-y: center;
    }
    .zhwc {
      min-width: 204px;
      min-height: 54px;
      position: absolute;
      top: 132px;
      left: 0;
      .zhwc1 {
        min-width: 64px;
        min-height: 22px;
        font-size: 18px;
        font-weight: 400;
        color: #ffffff;
        position: absolute;
        top: 18px;
        left: 24px;
      }
    }
    .zhwc:hover {
      background-color: gray;
      background-image: url(./components/imgs/icon1.png);
      background-repeat: no-repeat;
      background-position-x: 150px;
      background-position-y: center;
    }
    .Wisdom_Toolbox {
      min-width: 204px;
      height: 54px;
      position: absolute;
      top: 186px;
      left: 0;
      .Wisdom_Toolbox1 {
        min-width: 64px;
        height: 22px;
        font-size: 18px;
        font-weight: 400;
        color: #ffffff;
        position: absolute;
        top: 18px;
        left: 24px;
      }
    }
    .Wisdom_Toolbox:hover {
      background-color: gray;
      background-image: url(./components/imgs/icon1.png);
      background-repeat: no-repeat;
      background-position-x: 150px;
      background-position-y: center;
    }
    .Wisdom_office {
      min-width: 204px;
      height: 54px;
      position: absolute;
      top: 240px;
      left: 0;
      .Wisdom_office1 {
        min-width: 64px;
        height: 22px;
        font-size: 18px;
        font-weight: 400;
        color: #ffffff;
        position: absolute;
        top: 18px;
        left: 24px;
      }
    }
    .Wisdom_office:hover {
      background-color: gray;
      background-image: url(./components/imgs/icon1.png);
      background-repeat: no-repeat;
      background-position-x: 150px;
      background-position-y: center;
    }
    .zhbwg {
      min-width: 204px;
      height: 54px;
      position: absolute;
      top: 294px;
      left: 0;
      .zhbwg1 {
        min-width: 64px;
        height: 22px;
        font-size: 18px;
        font-weight: 400;
        color: #ffffff;
        position: absolute;
        top: 18px;
        left: 24px;
      }
    }
    .zhbwg:hover {
      background-color: gray;
      background-image: url(./components/imgs/icon1.png);
      background-repeat: no-repeat;
      background-position-x: 150px;
      background-position-y: center;
    }
  }
}
.el-icon-arrow-down:before {
  display: none;
}
</style>
